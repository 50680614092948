<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: "App",
  components: {MainMenu}
}
</script>

<template>
  <MainMenu/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
